var $window, $document, $body, globalXHRStatus, yModalConfig;

var conf = {
    'fancyStatus':false,
    'nodeBody':$('#body'),
    'fancyDefault':{
        wrapCSS: 'fb-fancy-default',
        padding: 0,
        margin: 0,
        autoScale: false,
        fitToView: false,
        openSpeed: 300,
        closeSpeed: 300,
        nextSpeed: 300,
        prevSpeed: 300,
        closeBtn : false,
        toolbar : false,
        afterShow:function(){
            conf.nodeBody.addClass('body-state-fancy');

            // this modal parent
            var $thisFancy = $('.fancybox-inner');
            moduleApp.executeModules($thisFancy);

        },
        beforeClose:function(){
            conf.nodeBody.removeClass('body-state-fancy');
        },
        afterClose:function(){
            conf.fancyStatus = false;
        }
    }
};

var moduleApp = {
    'init': function () {
        this.initGlobals();
        this.initGlobalsEvents();
        this.startupMessage();
        this.executeModules();
        this.loaderImages();
        this.pageLoader();
    },
    'getRandom':function(min,max){
        return Math.round(min - 0.5 + Math.random() * (max - min + 1));
    },
    'initGlobals':function(){
        $window = $(window);
        $document = $(document);
        $body = $('#body');
        globalXHRStatus = false;
        $window.on('load',function(){
            $window.trigger('resize');
        });
    },
    'initGlobalsEvents':function(){
        
        var methods = {
            'init':function(){
                methods.getInitialEvents('click');
                methods.getInitialEvents('change');
            },
            'getInitialEvents':function(thisEvent){
                $document.on(thisEvent,'[data-g'+thisEvent+']',function(e){
                    e.preventDefault();
                    var $this = $(this);
                    var thisAction = $this.attr('data-g'+thisEvent);
                    var thisValue = $this.val() || $this.data().value;
                    if (actions[thisAction]) { actions[thisAction]($this,thisValue); }
                    else { console.log('Event: '+thisEvent+', Action:"'+thisAction+'" - not defined'); }
                });
            },
            'getTarget':function(thisTarget){
                return $('[data-target="'+thisTarget+'"]');
            }
        };

        var actions = {

            'menuClose':function(){
                $body.removeClass('no-scroll');
                $('#mobileMenu').removeClass('is-active');
            },
            'menuOpen': function() {
                $body.addClass('no-scroll');
                $('#mobileMenu').addClass('is-active');
            },
            'closeFancy':function(){
                $.fancybox.close();
            },
            'share-page':function($this,thisValue){
                var shareService = thisValue;
                var shareData = {
                    'link':$('[property="og:url"]').attr('content'),
                    'title':encodeURI($('[property="og:title"]').attr('content')),
                    'description':encodeURI($('[property="og:description"]').attr('content')),
                    'image':$('[property="og:image"]').attr('content')
                };

                var windowLink = 'http://share.yandex.ru/go.xml?service=' + shareService;
                var fbWindowLink = 'http://www.facebook.com/sharer/sharer.php?';
                windowLink += '&title=' + shareData.title;

                if (shareService=='twitter') {
                    windowLink += ' ' + shareData.description;
                    windowLink += '&url=' + shareData.link;
                    windowLink += '&link=' + shareData.link;
                } else if (shareService=='facebook'){
                    fbWindowLink += 'u=' + shareData.link;
                } else {
                    windowLink += '&url=' + shareData.link;
                    windowLink += '&link=' + shareData.link;
                    windowLink += '&description=' + shareData.description;
                    windowLink += '&image=' + shareData.image;
                }
                if (shareService == 'facebook'){
                    window.open(fbWindowLink,'','toolbar=0,status=0,width=625,height=435');
                } else {
                    window.open(windowLink,'','toolbar=0,status=0,width=625,height=435');
                }
            },
            'print-page':function(){
                window.print();
            },
            'scroll-to-anchor': function($this) {
                var target = $this.attr('href');
                if( target.length ) {
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top -70
                    }, 700);
                }
            },
            'scroll-top':function() {
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            }
        };

        methods.init();
    },
    'executeModules':function(){
        $('[data-is]').each(function(i,thisModule){
            var $thisModule = $(thisModule);
            var thisModuleName = $thisModule.attr('data-is');
            if(moduleApp[thisModuleName]) {
                console.log('Auto start: `' + thisModuleName + '`.');
                moduleApp[thisModuleName]($thisModule);
            } else {
                console.log('Module: `' + thisModuleName + '` is not defined.');
            }
        });
    },
    'pageLoader': function(){
        $body.addClass('jsfx-loaded');
        setTimeout(function(){ moduleApp.inViewLoader(); }, 300);
    },
    'inViewLoader':function(){
        var hiddenClass = 'view-hidden';
        var $inView = $('.'+hiddenClass);

        $inView.each(function(i,e){
            var $e = $(e);
            var r1 = parseInt(Math.random() * 1000) + 500;
            var r2 = parseInt(Math.random() * 200);
            var string = 'transition-duration:'+r1+'ms;transition-delay:'+r2+'ms;transition-property:opacity;';
            var style = $e.attr('style');
            if (style) { string = string + style; }
            $e.attr('style',string);

        });

        $inView.on('inview', function(event, isInView) {
            if (isInView) { $(this).removeClass(hiddenClass); }
        });
    },
    'startupMessage':function(){
        if (appConfig.startupMessage.title && appConfig.startupMessage.message) {
            var t = '<div class="fb-modal-default">';
            t += '<a class="is-cross-link" data-gclick="closeFancy" href="#"><img src="/assets/img/icon-cross.svg" alt=""></a>';
            t += '<div class="md-body">'+appConfig.startupMessage.message+'</div>';
            t += '</div>';

            var thisFancyConfig = $.extend({}, conf.fancyDefault, {
                content: t
            });

            $.fancybox.open(t, thisFancyConfig);
        }
    },  
    'loaderImages':function($img){

        var inviewFlag = true;
        if (!$img) { $img = $('[data-img]'); }

        $img.each(function(i,thisImg){

            var $thisImage = $(thisImg);
            var $parentNode = $thisImage.parent();

            var imgUrl = $thisImage.data().img || '';
            var imgMiddle = $thisImage.data().middle || false;
            var imgVisible = $thisImage.data().visible || false;
            var nativeStyle = $thisImage.attr('style') || '';

            if (!imgVisible) { $thisImage.addClass('sfx-hidden'); }

            $thisImage.removeAttr('data-img');
            var tempImage = new Image();
            tempImage.src = imgUrl;
            tempImage.onload = function(){


                var r1 = moduleApp.getRandom(500,1200);
                var r2 = moduleApp.getRandom(0,300);

                if ($thisImage.is('img')) {
                    $thisImage.attr('src',imgUrl);


                    if (imgMiddle) {
                        var middleArray = { };
                        middleArray.width = parseInt($thisImage.width());
                        middleArray.height = parseInt($thisImage.height());
                        middleArray.halfTop = parseInt(middleArray.height/2);
                        middleArray.halfLeft = parseInt(middleArray.width/2);
                        nativeStyle += 'position:absolute;display:block;top:50%;left:50%;width:'+middleArray.width+'px;height:'+middleArray.height+'px;margin:-'+middleArray.halfTop+'px 0 0 -'+middleArray.halfLeft+'px;';
                    }

                    $thisImage.attr('style',nativeStyle + 'transition-property:opacity;transition-duration:'+r1+'ms;transition-delay:'+r2+'ms;');
                } else {
                    $thisImage.attr('style',nativeStyle+'background-image:url('+imgUrl+');transition-property:opacity;transition-duration:'+r1+'ms;transition-delay:'+r2+'ms;');
                }
                if($parentNode.hasClass('js-zoom-image')) $parentNode.zoom();
            };
        });

        if (inviewFlag) {
            $img.on('inview', function(event, isInView, howView) {
                if (isInView) {
                    var $this = $(this);
                    $(this).removeClass('sfx-hidden');
                }
            });
        } else {
            $img.removeClass('sfx-hidden');
        }
    },
    'social-carousel':function($thisModule) {
      var galleryThumbs = new Swiper('#socialBtns', {
          slidesPerView: 4,
          touchRatio: 0,
          shortSwipes: false,
          longSwipes: false,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          slideToClickedSlide: true
      });
      var swiper = new Swiper($thisModule.find('#sWidgets'), {
        slidesPerView:1,
        spaceBetween: 10,
        thumbs: {
          swiper: galleryThumbs
        },
        on: {
          slideChange: function () {
            console.log('ch');
          }
        }
      });
    },
    'chart': function($thisModule) {
      var $chart = $thisModule.find('#chartJs');
      var chartData = $thisModule.data('chart');
      var ctx = document.getElementById('chartJs').getContext('2d');
      var myChart = new Chart(ctx, {
          type: 'line',
          data: {
              labels: chartData.dates,
              datasets: [{
                  label: false,
                  data: chartData.donations,
                  backgroundColor: 'rgba(233, 157, 33, 0.1)',
                  borderColor: '#e99d21',
                  borderWidth: 2
              }]
          },
          options: {
            responsive: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            legend: {
              display: false
            },
            elements: {
              point: {
                backgroundColor: '#e99d21',
                borderColor: '#e99d21',
                radius: 3,
                borderWidth: 0
              }
            },
            tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,

                custom: function(tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        // var titleLines = tooltipModel.title || [];
                        var bodyLines = tooltipModel.body.map(getBody);

                        var innerHtml = '<thead>';

                        // titleLines.forEach(function(title) {
                        //     innerHtml += '<tr><th>' + '</th></tr>';
                        // });
                        innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function(body, i) {
                            var colors = tooltipModel.labelColors[i];
                            var style = 'background: transparent';
                            style += '; border-color: transparent';
                            style += '; border-width: 0';
                            style += '; padding: 2px';
                            style += ';font-size: 16px';
                            style += ';font-weight: 600';
                            style += ";font-family: 'PF BeauSans Pro',sans-serif";
                            var span = '<span style="' + style + '"></span>';
                            innerHtml += '<tr><td style="' + style + '">' + span + body + ' руб'+ '</td></tr>';
                        });
                        innerHtml += '</tbody>';

                        var tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
          }
      });
    },
    'form-validation':function($thisModule,thisCallback){

      thisCallback = thisCallback || false;

      var params = {
        'fields':{
          'attr':'validation',
          'parentClass':'is-form-field',
          'parentErrorState':'state-error',
          'errorNode':'ff-error-parent',
          'formFocusClass':'state-focus',
          'formHasValueClass':'state-has-value',
          'fromInitedClass':'state-inited',
          'fieldInitidClass':'_field-inited',
          'initialNodes':$(null),
          'passwordFields':{
            'primaryNode':$(null),
            'secondaryNode':$(null)
          },
          'maskedBlank':'–'
        },
        'form':{
          'moduleParent':$thisModule,
          'parent':$thisModule.find('form'),
          'submitLinkClass':'js-form-submit',
          'validFormClass':'form-state-valid',
          'invalidFormClass':'form-state-invalid',
          'progressFormClass':'form-state-progress',
          'progressButtonClass':'button-state-progress'
        },
        'inner':{
          'submitCallback':thisCallback,
          'realTimeCheck':false,
          'defaultFieldData':{
            'mask':'none',
            'require':true,
            'visible':true,
            'error':'',
            'cleaveMask':false
          }
        }
      };

      var methods = {
        'init':function(){
          methods.initFields();
          methods.initEvents();
          methods.initFieldsMarkup();
          methods.initFormStatus();
        },
        'initFields':function(){
          params.fields.initialNodes = params.form.parent.find('[data-'+params.fields.attr+']');
        },
        'initEvents':function(){
          params.form.parent.on('submit', methods.eventFormSubmit);
          params.form.parent.find('.'+params.form.submitLinkClass).on('click', methods.eventSubmitLinkClick);
          $thisModule.on('keypress input change','[data-'+params.fields.attr+']',methods.eventChangeFields);
          $thisModule.on('keydown','[data-'+params.fields.attr+']',methods.eventFormSubmitEnter);
          $thisModule.on('focus','[data-'+params.fields.attr+']',methods.eventFocusEnter);
          $thisModule.on('blur','[data-'+params.fields.attr+']',methods.eventFocusLeave);
        },
        'initFieldsMarkup':function(){
          params.form.parent.find('[data-'+params.fields.attr+']:not(.'+params.fields.fieldInitidClass+')').each(function(i,thisField){
            var $thisField = $(thisField);
            var thisFieldDataRaw = $thisField.data()[params.fields.attr];
            var thisFieldData = $.extend({}, params.inner.defaultFieldData, thisFieldDataRaw);

            // error message
            if(thisFieldData.error) { $thisField.closest('.'+params.fields.parentClass).append('<div class="'+params.fields.errorNode+'">'+thisFieldData.error+'</div>'); }

            // password fields
            if(thisFieldData.mask == "passwordPrimary") { params.fields.passwordFields.primaryNode = $thisField; }
            if(thisFieldData.mask == "passwordSecondary") { params.fields.passwordFields.secondaryNode = $thisField; }

            // masked input
            if(thisFieldData.cleaveMask) {
              $thisField.mask("+7 (999) 999-9999", {placeholder:""})
            }

            $thisField.addClass(params.fields.fieldInitidClass);
            $thisField.closest('.'+params.fields.parentClass).addClass(params.fields.fromInitedClass);
          });
        },
        'initFormStatus':function(){
          if(methods.checkingFieldsAll(params.fields.initialNodes,true)) {
            params.form.moduleParent.addClass(params.form.validFormClass);
            params.form.moduleParent.removeClass(params.form.invalidFormClass);
          } else {
            params.form.moduleParent.removeClass(params.form.validFormClass);
            params.form.moduleParent.addClass(params.form.invalidFormClass);
          }
        },
        'eventFormSubmitEnter':function(e){
          if (e.keyCode == 13) { methods.eventFormSubmit(); return false; }
          return true;
        },
        'eventFormSubmit':function(){
          methods.initFields();
          methods.initFieldsMarkup();
          methods.initFormStatus();

          // if form in progress
          if (params.form.moduleParent.hasClass(params.form.progressFormClass)) { return false; }

          // enable real time checking
          params.inner.realTimeCheck = true;

          // return state - is a error state
          // if `FALSE` - fields has error and submit is prevent
          // if `TRUE` - no error and form going submit
          var returnState = methods.checkingFieldsAll(params.fields.initialNodes);

          // if fields valid then add progress state to form

          if (returnState == true) {
            params.form.moduleParent.addClass(params.form.progressFormClass);
            $('.'+params.form.submitLinkClass).addClass(params.form.progressButtonClass);
          }

          // if submit callback
          if (returnState && params.inner.submitCallback) {
            params.inner.submitCallback(params.form.parent, params.form.moduleParent);
            return false;
          }

          return returnState;
        },
        'eventSubmitLinkClick':function(e){
          e.preventDefault();
          params.inner.submitCallback = function() {
            $.ajax({
              url: params.form.parent.attr('action'),
              method:'post',
              data: params.form.parent.serialize(),
              success: function(response){
                var res = $.parseJSON(response);
                var t = '<div class="is-modal is-style">';
                  t += '<div class="is-modal__inner">';
                  t += '<span class="is-modal__close" data-gclick="closeFancy"></span>';
                  t += '<h4 class="is-modal__title">'+res.title+'</h4>';
                  t += '<p class="is-modal__text">'+res.msg+'</p>';
                  t += '</div>';
                  t += '</div>';

                $.fancybox.open(t);
                
                params.form.parent[0].reset();
                params.form.moduleParent.removeClass(params.form.progressFormClass);
                $('.'+params.form.submitLinkClass).removeClass(params.form.progressButtonClass);
              },
              error:function(err){
                console.log(err);
              }
            });
          };
          methods.eventFormSubmit();
        },
        'eventChangeFields':function(){
          var $thisField = $(this);
          var thisFieldDataRaw = $.extend({}, $thisField.data()[params.fields.attr], {
            thisField:$thisField,
            thisValue:$.trim($thisField.val())
          });

          // checking this field
          if(params.inner.realTimeCheck) { methods.checkingField($thisField); }

          // hidden checking all field for submit status
          methods.initFormStatus();

          // double fields logic, trigger secondary on primary
          if (thisFieldDataRaw.mask == "passwordPrimary") { params.fields.passwordFields.secondaryNode.trigger('change'); }
        },
        'checkingField':function($thisField,hiddenChecking){
          // hiddenChecking is check fields but don't change style

          var fieldStatus = true;

          var thisFieldDataRaw = $.extend({}, $thisField.data()[params.fields.attr], {
              thisField:$thisField,
              thisValue:$.trim($thisField.val())
          });

          var thisFieldData = $.extend({}, params.inner.defaultFieldData, thisFieldDataRaw);

          // checking empty value

          if ($thisField.val().length === 0) {
            $thisField.closest('.'+params.fields.parentClass).removeClass(params.fields.formHasValueClass);
          } else {
            $thisField.closest('.'+params.fields.parentClass).addClass(params.fields.formHasValueClass);
          }

          // checking for mask
          if (methods.checkingMasks[thisFieldData.mask]) {
            if (methods.checkingMasks[thisFieldData.mask](thisFieldData) || methods.checkingMasks['visibleAndRequire'](thisFieldData)) {
              // remove error class
              if (!hiddenChecking) { $thisField.closest('.'+params.fields.parentClass).removeClass(params.fields.parentErrorState); }
            } else {
              // added error class
              if (!hiddenChecking) { $thisField.closest('.'+params.fields.parentClass).addClass(params.fields.parentErrorState); }

              // added error status
              fieldStatus = false;
            }
          }

          return fieldStatus;
        },
        'checkingFieldsAll': function($fields,hiddenChecking){
          var checkingParams = {
            'status':true,
            'focusFlag':true,
            'scrollNode':$('body,html'),
            'scrollSpeed':300,
            'scrollDelay':100,
            'scrollOffsetShift': 300
          };
          $fields.each(function(i,thisField){
            var $thisField = $(thisField);
            var thisFieldStatus = methods.checkingField($thisField,hiddenChecking);
            if (!thisFieldStatus) {
              checkingParams.status = false;

              // scroll to error field and focus
              if (checkingParams.focusFlag && !hiddenChecking && !$thisField.is(':checkbox')) {
                checkingParams.focusFlag = false;
                $thisField.focus();
              }
            }
          });
          return checkingParams.status;
        },
        'checkingMasks':{
          'text':function(data){
            return (data.thisValue.length > 0);
          },
          'phone':function(data){
            return (data.thisValue.length === 17);
          },
          'textInn':function(data){
            return (data.thisValue.length === 12);
          },
          'textBik':function(data){
            return (data.thisValue.length === 9);
          },
          'email':function(data){
            return (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(data.thisValue));
          },
          'select':function(data){
            return !(data.thisValue == 0);
          },
          'checkbox':function(data){
            return (data.thisField.is(':checked'));
          },
          'customRadio':function(data){
            return (data.thisValue.length > 0 && data.thisValue != 0);
          },
          'visibleAndRequire':function(data){
            // invisible field
            if (data.visible && !data.thisField.closest('.'+params.fields.parentClass).is(':visible')) { return true; }

            // not require field
            if (!data.require && data.thisValue.length === 0) { return true; }

            // return default
            return false;
          },
          'passwordPrimary':function(data){
            return (data.thisValue.length > 5);
          },
          'passwordSecondary':function(data){
            return (data.thisValue.length > 5 && (params.fields.passwordFields.primaryNode.val() === params.fields.passwordFields.secondaryNode.val()));
          },
          'selectChosen':function(data){
            return (data.thisValue.length > 0);
          },
          'postCode':function(data){
            return (/^\d+$/.test(data.thisValue) && data.thisValue.length === 6);
          },
          'groupRadio':function(data){
            return data.thisField.closest('.field-group').find('input:checked').length;
          },
          'groupCheckbox':function(data){
            return data.thisField.closest('.field-group').find('input:checked').length;
          }
        },
        'eventFocusEnter':function(){
          $(this).closest('.'+params.fields.parentClass).addClass(params.fields.formFocusClass);
        },
        'eventFocusLeave':function(){
          $(this).closest('.'+params.fields.parentClass).removeClass(params.fields.formFocusClass)
        }
      };

      methods.init();
    },
    'scriptLoading':function(src, callback) {
        var script = document.createElement('script')
        script.type = 'text/javascript';
        if(script.readyState) {
          script.onreadystatechange = function() {
            if ( script.readyState === "loaded" || script.readyState === "complete" ) {
              script.onreadystatechange = null;
              callback();
            }
          }
        } else {
          script.onload = function() {
            callback();
          };
        }
        script.src = src;
        document.getElementsByTagName('head')[0].appendChild(script);
    },
    'contacts-map': function($thisModule) {
        var config = {
            API: 'https://api-maps.yandex.ru/2.1/?apikey=b8c005c3-1055-43da-b4d2-6df6a144c130&lang=ru_RU'
        }
        this.scriptLoading(config.API, function() {
            console.log('yandex-map API loaded');
            ymaps.ready(function () {
                var cMap = new ymaps.Map('map', {
                        center: [54.312495070291554,48.386998000000006],
                        controls: ["typeSelector", "zoomControl"],
                        zoom: 18
                    }),

                    myPlacemark = new ymaps.Placemark(cMap.getCenter(), {
                        hintContent: 'г. Ульяновск, улица Железной Дивизии, д. 15',
                    }, {
                        iconLayout: 'default#image',
                        iconImageHref: '/img/marker.svg',
                        iconImageSize: [60, 68],
                        iconImageOffset: [-30, -38]
                    });
                cMap.behaviors.disable('scrollZoom');

                cMap.geoObjects.add(myPlacemark);
            });
        })
    },
    'handle-file-upload': function($thisModule) {
      if (window.File && window.FileList && window.FileReader) {
        var $parent = $thisModule.closest('.is-form-field');
        var $placeholder = $parent.find('.input-placeholder');
        var $clearBtn = $parent.find('.js_clear-field');
        var $inptBtn = $parent.find('.js_input-trigger');
        $thisModule.on('change', function(e) {
          var files = e.target.files,
          filesLength = files.length;
          if(filesLength) $parent.addClass('is-attached');
          for (var i = 0; i < filesLength; i++) {
            var f = files[i];
            var fileReader = new FileReader();
            $placeholder.text(f.name);
              // fileReader.readAsDataURL(f);
          }
        });
        $inptBtn.on('click', function() {
          $thisModule.trigger('click');
        })
        $clearBtn.on('click', function() {
          $thisModule.val('');
          $parent.removeClass('is-attached');
          $placeholder.text('Файл не выбран');
        })
      }
    },
    'gallery':function($thisModule) {
      var galleryThumbs,
          swiper,
          captionData,
          $main = $thisModule.find('.js_gallery-top'),
          $thumbs = $thisModule.find('.js_gallery-thumbs'),
          $pager = $thisModule.find('.js_pager'),
          $caption = $thisModule.find('.js_caption'),
          $name = $caption.find('.name'),
          $age =  $caption.find('.age'),
          $btnPrev = $thisModule.find('.js_btn-prev'),
          $btnNext = $thisModule.find('.js_btn-next');
      galleryThumbs = new Swiper($thumbs, {
          slidesPerView: 7,
          spaceBetween: 12,
          preloadImages: false,
          lazy: {
            loadPrevNext: true,
          },
          slideToClickedSlide: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          breakpoints: {
            480: {
              slidesPerView: 3,
              spaceBetween: 10
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 12
            }
          }
      });
      swiper = new Swiper($main, {
        slidesPerView:1,
        spaceBetween: 10,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
        },
        navigation: {
          nextEl: $btnNext,
          prevEl: $btnPrev
        },
        thumbs: {
          swiper: galleryThumbs
        },
        pagination: {
          el: $pager[0],
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return current + ' из ' + total;
          }
        },
        on: {
          init: function() {
            captionData = $(this.$el[0]).find('.swiper-slide-active').data('caption');
            showCaption(captionData);
          },
          slideChangeTransitionStart: function() {
            captionData = $(this.$el[0]).find('.swiper-slide-active').data('caption');
            showCaption(captionData);
          }
        }
      });

      function showCaption(caption) {
        var $strArr = caption.split('|'),
            name = $strArr[0],
            age = $strArr[1];
        $name.text(name);
        $age.text(age);
      }
    }
};

var pageApp = {
    'init': function(){
        var curApp = $('#app').attr('data-app');
        if (pageApp[curApp]) { pageApp[curApp](); }
    },
    'page-index':function(){
        console.log('index-here');
    }
};


$(document).ready(function(){ 
    moduleApp.init(); 
    // pageApp.init();

});
